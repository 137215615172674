<template>
  <div class="confirm">
    <h1>ユーザ登録確認</h1>
    <v-form v-model="valid" ref="form" lazy-validation>
      <v-text-field v-model="username" :rules="userRules" label="ユーザ名" required/>
      <v-text-field v-model="code" :rules="codeRules" label="確認コード" required/>
      <v-btn :disabled="!valid" @click="submit" color="primary">送信</v-btn>
    </v-form>
    <v-btn @click="resend">確認コード再発行</v-btn>
  </div>
</template>

<script>
import {confirmSignUp, resendSignUp} from '@/js/auth.js'
export default {
  name: "SignUpConfirm",
  data() {
    return {
      valid: false,
      username: '',
      code: '',
    }
  },
  computed: {
    userRules() {
      return [
        v => !!v || 'User Name is required',
      ]
    },
    codeRules() {
      return [
        v => !!v || 'Code is required',
        v => (v && v.length === 6) || 'Code must be 6 digits'
      ]
    },
  },
  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        console.log(`CONFIRM username: ${this.username}, code: ${this.code}`);
        if(confirmSignUp(this.username, this.code)){
          alert('ユーザが使用可能になりました。')
        }
      }
    },
    resend() {
      console.log(`RESEND username: ${this.username}`);
      if(resendSignUp(this.username)){
        alert('確認コードを再発行しました。');
      }
    }
  },
}
</script>